@import 'theme';
@import 'theme/themes';

html.theme-wrapper {
  .details-nav-container {
    $border-radius-size: 4px;
    $details-nav-height: 80px;
    $details-nav-btn-width: 35px;

    display: flex;
    white-space: nowrap;
    height: $details-nav-height;
    min-width: 0;

    .details-nav-title {
      padding: 0px;
      background-color: initial;
      border-width: 0px;
    }

    .details-nav-content {
      display: flex;
      gap: 16px;
      padding: 0px 20px;
      height: $details-nav-height;
      width: calc(100% - #{(2 * $details-nav-btn-width)});
      min-width: 0;

      @include make-themable-global($themes) {
        background-color: theme-value('details-navigation-background');
      }

      h2 {
        display: inline;
      }

      .label-tiere {
        padding: 2px 8px;
        font-size: 11px;
        font-weight: 100;
        display: inline-block;
        vertical-align: super;

        -webkit-border-radius: 10px;
        border-radius: 10px;

        @include make-themable-global($themes) {
          color: theme-value('details-navigation-text-label');
          background-color: theme-value('details-navigation-background-label');
        }
      }

      .details-nav-content-main-container {
        flex: 3;
      }

      .details-nav-content-secondary-container {
        flex: 1;
      }
    }

    .details-nav-content-row {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .details-nav-btn-container {
      display: inline-block;
      vertical-align: top;
      height: $details-nav-height;
      width: $details-nav-btn-width;
    }

    .details-nav-btn {
      height: 100%;
      width: 100%;
    }

    .details-nav-btn-prev {
      -webkit-border-top-left-radius: $border-radius-size;
      -webkit-border-bottom-left-radius: $border-radius-size;
      -moz-border-radius-topleft: $border-radius-size;
      -moz-border-radius-bottomleft: $border-radius-size;
      border-top-left-radius: $border-radius-size;
      border-bottom-left-radius: $border-radius-size;

      -webkit-border-top-right-radius: 0px;
      -webkit-border-bottom-right-radius: 0px;
      -moz-border-radius-topright: 0px;
      -moz-border-radius-bottomright: 0px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    .details-nav-btn-next {
      -webkit-border-top-left-radius: 0px;
      -webkit-border-bottom-left-radius: 0px;
      -moz-border-radius-topleft: 0px;
      -moz-border-radius-bottomleft: 0px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;

      -webkit-border-top-right-radius: $border-radius-size;
      -webkit-border-bottom-right-radius: $border-radius-size;
      -moz-border-radius-topright: $border-radius-size;
      -moz-border-radius-bottomright: $border-radius-size;
      border-top-right-radius: $border-radius-size;
      border-bottom-right-radius: $border-radius-size;
    }
  }
}
