@import 'theme';
@import 'theme/themes';

html.theme-wrapper {
  /* General */
  table.table {
    margin-bottom: 0px;
  }

  .table {
    thead > tr > th {
      @include make-themable-global($themes) {
        background-color: theme-value('background-table-header');
      }
    }

    tbody > tr > td {
      .btn-table-cell {
        padding: 0.1rem 0.4rem;
      }
    }
  }

  .table tbody > tr > td {
    vertical-align: middle;
  }

  .table > thead > tr > th {
    vertical-align: top;
  }

  .table {
    thead,
    tbody,
    tfoot {
      > tr {
        > th,
        > td {
          padding: 5px;
        }
      }
    }
  }

  /* Table Borders */

  .table-bordered .cell-right-border,
  .table-fixed-header.table-bordered .cell-right-border {
    @include make-themable-global($themes) {
      border-right: 4px solid theme-value('border-highlight');
    }
  }

  .table-bordered .cell-left-border,
  .table-fixed-header.table-bordered .cell-left-border {
    @include make-themable-global($themes) {
      border-left: 4px solid theme-value('border-highlight');
    }
  }

  .cell-bottom-border {
    @include make-themable-global($themes) {
      border-bottom: 4px solid theme-value('border-highlight');
    }
  }

  .cell-top-border {
    @include make-themable-global($themes) {
      border-top: 4px solid theme-value('border-highlight');
    }
  }

  .table .expand-button {
    width: 22px;

    @include make-themable-global($themes) {
      color: theme-value('topbar-navigation-background');
    }
  }

  .table .expand-button span {
    font-weight: bold;
    line-height: 18px;
    font-size: 16px;
  }

  .table .btn-no-margin {
    margin-bottom: 0;
  }

  /* Striped Tables mit hover*/

  .table-striped > tbody > tr:nth-child(even) {
    @include make-themable-global($themes) {
      background-color: theme-value('background-table-striped-even');
    }

    &:hover {
      @include make-themable-global($themes) {
        background-color: theme-value('background-table-striped-even-hover');
      }
    }
  }

  /* Gruen */

  .table-striped > tbody > tr.ampel-gruen-background {
    @include make-themable-global($themes) {
      background-color: theme-value('ampel-gruen-table-background');
    }
  }

  .table-striped > tbody > tr > td.ampel-gruen-background {
    @include make-themable-global($themes) {
      background-color: theme-value('ampel-gruen-table-background');
    }
  }

  .table-bordered > tbody > tr.ampel-gruen-background {
    @include make-themable-global($themes) {
      background-color: theme-value('ampel-gruen-table-background');
    }
  }

  .table-bordered > tbody > tr > td.ampel-gruen-background {
    @include make-themable-global($themes) {
      background-color: theme-value('ampel-gruen-table-background');
    }
  }

  .table-hover > tbody > tr:hover.ampel-gruen-background {
    @include make-themable-global($themes) {
      background-color: theme-value('ampel-gruen-table-background-hover');
    }
  }

  .table-hover > tbody > tr:hover > td.ampel-gruen-background {
    @include make-themable-global($themes) {
      background-color: theme-value('ampel-gruen-table-background-hover');
    }
  }

  /* Rot */

  .table-striped > tbody > tr.ampel-rot-background {
    @include make-themable-global($themes) {
      background-color: theme-value('ampel-rot-table-background');
    }
  }

  .table-striped > tbody > tr > td.ampel-rot-background {
    @include make-themable-global($themes) {
      background-color: theme-value('ampel-rot-table-background');
    }
  }

  .table-bordered > tbody > tr.ampel-rot-background {
    @include make-themable-global($themes) {
      background-color: theme-value('ampel-rot-table-background');
    }
  }

  .table-bordered > tbody > tr > td.ampel-rot-background {
    @include make-themable-global($themes) {
      background-color: theme-value('ampel-rot-table-background');
    }
  }

  .table-hover > tbody > tr:hover.ampel-rot-background {
    @include make-themable-global($themes) {
      background-color: theme-value('ampel-rot-table-background-hover');
    }
  }

  .table-hover > tbody > tr:hover > td.ampel-rot-background {
    @include make-themable-global($themes) {
      background-color: theme-value('ampel-rot-table-background-hover');
    }
  }

  /* Gelb */

  .table-striped > tbody > tr.ampel-gelb-background {
    @include make-themable-global($themes) {
      background-color: theme-value('ampel-gelb-table-background');
    }
  }

  .table-striped > tbody > tr > td.ampel-gelb-background {
    @include make-themable-global($themes) {
      background-color: theme-value('ampel-gelb-table-background');
    }
  }

  .table-bordered > tbody > tr.ampel-gelb-background {
    @include make-themable-global($themes) {
      background-color: theme-value('ampel-gelb-table-background');
    }
  }

  .table-bordered > tbody > tr > td.ampel-gelb-background {
    @include make-themable-global($themes) {
      background-color: theme-value('ampel-gelb-table-background');
    }
  }

  .table-hover > tbody > tr:hover.ampel-gelb-background {
    @include make-themable-global($themes) {
      background-color: theme-value('ampel-gelb-table-background-hover');
    }
  }

  .table-hover > tbody > tr:hover > td.ampel-gelb-background {
    @include make-themable-global($themes) {
      background-color: theme-value('ampel-gelb-table-background-hover');
    }
  }

  /* Filter */

  thead th:nth-last-child(1).iq-table-filter ul.dropdown-menu,
  thead th:nth-last-child(2).iq-table-filter ul.dropdown-menu,
  thead th:nth-last-child(3).iq-table-filter ul.dropdown-menu {
    left: auto;
  }

  /* Pagination */

  #main-wrapper {
    .pagination > .active > a,
    .pagination > .active > span,
    .pagination > .active > a:hover,
    .pagination > .active > span:hover,
    .pagination > .active > a:focus,
    .pagination > .active > span:focus {
      @include make-themable-global($themes) {
        color: theme-value('text-2');
        background-color: theme-value('primary-schmuckfarbe');
      }
    }

    .pagination {
      margin: 0px;
    }
  }

  #main-wrapper {
    table th.activeFilter {
      @include make-themable-global($themes) {
        background-color: theme-value('primary-schmuckfarbe');
      }
    }
  }

  table td.truncate-text {
    span {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
}
