@import 'theme';
@import 'theme/themes';

html.theme-wrapper {
  position: relative;
  min-height: 100%;

  .check-icon {
    width: 24px;
    height: 24px;
    background: url('../assets/images/check.svg') center / 20px no-repeat;
  }

  body {
    font-family: 'open sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 0.8125rem;
    font-weight: normal;
    @include make-themable-global($themes) {
      color: theme-value('text');
    }
    overflow-x: auto;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 100;
  }

  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 16px;
  }

  h4 {
    font-size: 14px;
  }

  h5 {
    font-size: 12px;
  }

  h6 {
    font-size: 10px;
  }

  h3,
  h4,
  h5 {
    margin-top: 5px;
    font-weight: 600;
  }

  /* Elemente auf den der Cursor zum Pointer werden soll */
  button,
  .btn,
  a,
  .panel-heading,
  .iq-options-button,
  .iq-sort-container {
    cursor: pointer;
  }

  button:disabled,
  button.disabled,
  .btn:disabled,
  .btn.disabled,
  .panel-heading:disabled,
  .panel-heading.disabled,
  .iq-options-button:disabled,
  .iq-options-button.disabled {
    cursor: not-allowed;
  }

  // TODO: später nochmal prüfen
  //  Schlachtdaten -> Lieferpartien ->  Befunde
  // Die Accordion Headlines können nicht mit font-weight: bolder fett dargestellt werden.
  b,
  strong {
    font-weight: 700;
  }

  .bg-color {
    @include make-themable-global($themes) {
      background-color: theme-value('background');
    }
  }

  .content-bg-color {
    @include make-themable-global($themes) {
      background-color: theme-value('background-content');
    }
  }

  .hr-line-dashed {
    margin: 20px 0;

    @include make-themable-global($themes) {
      border-top: 1px dashed theme-value('hr-line');
    }
  }

  .hr-line-solid {
    margin-top: 15px;
    margin-bottom: 15px;

    @include make-themable-global($themes) {
      border-bottom: 1px solid theme-value('hr-line');
    }
  }

  /* Select Style */
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    border-radius: 0;
    font-size: 1em;

    &::-ms-expand {
      display: none;
    }
  }

  select {
    padding: 0.4em 1.4em 0.4em 0.5em;
    border-radius: 3px;
    background: initial;
    background-color: white;
    background-image: url('../assets/images/caret-down.svg') !important;
    background-size: 12px 12px;
    background-repeat: no-repeat;
    background-position: right 5px center;

    @include make-themable-global($themes) {
      border: 1px solid theme-value('border-input');
    }

    &.form-control {
      padding: 0.4em 1.4em 0.4em 0.5em;
      border-radius: 3px;
      background: initial;
      background-color: white;
      background-image: url('../assets/images/caret-down.svg') !important;
      background-size: 12px 12px;
      background-repeat: no-repeat;
      background-position: right 5px center;

      @include make-themable-global($themes) {
        border: 1px solid theme-value('border-input');
      }

      &.is-invalid {
        @include make-themable-global($themes) {
          border-color: theme-value('error');
        }
      }
    }
  }

  /* (indirekter) Webzugriff */

  .has-webzugriff {
    font-weight: bold;
    @include make-themable-global($themes) {
      color: theme-value('ampel-gruen');
    }
  }

  /* squares */

  .green-square {
    height: 25px;
    width: 25px;
    @include make-themable-global($themes) {
      background-color: theme-value('ampel-gruen');
      border: solid 1px theme-value('border');
    }
  }

  .yellow-square {
    height: 25px;
    width: 25px;

    @include make-themable-global($themes) {
      background-color: theme-value('ampel-gelb');
      border: solid 1px theme-value('border');
    }
  }

  .red-square {
    height: 25px;
    width: 25px;

    @include make-themable-global($themes) {
      background-color: theme-value('ampel-rot');
      border: solid 1px theme-value('border');
    }
  }

  .gray-square {
    height: 25px;
    width: 25px;
    background-color: $gray-200;

    @include make-themable-global($themes) {
      border: solid 1px theme-value('border');
    }
  }

  .white-square {
    height: 25px;
    width: 25px;
    background-color: $white;

    @include make-themable-global($themes) {
      border: solid 1px theme-value('border');
    }
  }

  .blue-square {
    height: 25px;
    width: 25px;
    background-color: $iq-blue;

    @include make-themable-global($themes) {
      border: solid 1px theme-value('border');
    }
  }

  .dark-gray-square {
    height: 25px;
    width: 25px;
    background-color: $gray-800;
    opacity: 0.4;

    @include make-themable-global($themes) {
      border: solid 1px theme-value('border');
    }
  }

  /* browserhappy */
  .browsehappy {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
  }

  /* IBox */
  .ibox {
    clear: both;
    margin-bottom: 25px;
    margin-top: 0;
    padding: 0;

    .ibox-title {
      -moz-border-bottom-colors: none;
      -moz-border-left-colors: none;
      -moz-border-right-colors: none;
      -moz-border-top-colors: none;
      border-color: $gray-300;
      -o-border-image: none;
      border-image: none;
      border-style: solid solid none;
      border-width: 2px 0 0;
      color: inherit;
      margin-bottom: 0;
      padding: 15px 15px 7px;
      min-height: 48px;

      @include make-themable-global($themes) {
        background-color: theme-value('background-ibox');
      }

      h4,
      h5,
      h6 {
        font-weight: 600;
        font-size: 0.875rem;
      }
    }

    .ibox-content {
      color: inherit;

      -o-border-image: none;
      border-image: none;
      border-style: solid solid none;
      border-width: 1px 0;

      @include make-themable-global($themes) {
        background-color: theme-value('background-ibox');
        border-color: theme-value('border');
      }
    }

    .ibox-footer {
      color: inherit;
      -o-border-image: none;
      border-image: none;
      border-style: solid solid none;
      border-width: 1px 0;

      @include make-themable-global($themes) {
        background-color: theme-value('background-ibox');
        border-color: theme-value('border');
      }
    }
  }

  /* pretty checkbox */
  .pretty {
    font-size: 1.25rem;

    &.first-checkbox {
      margin-top: 2px;
    }
  }

  /* error */
  .iq-middle-box {
    padding-top: 40px;

    h1 {
      font-size: 170px;
    }

    .error-desc {
      max-width: 400px;
      z-index: 100;
      margin: 0 auto;
    }
  }

  /* Manual Modal */
  // muss hier definiert werden, da das komplette Modal-Template dynamisch gebaut wird
  // und das gescopte Style-Sheet der Componente dort nicht greift

  .handbuch-modal {
    max-width: 1400px;
  }

  /* Lockscreen Modal */

  .modal-backdrop {
    &.lockscreen-backdrop {
      opacity: 1;

      @include make-themable-global($themes) {
        background: theme-value('background-body');
      }
    }
  }

  /* Hilfe-Modal */

  iq-help-button-modal {
    .hilfetext-button {
      padding: 2px 5px 2px 5px;
      text-decoration: none;
      border-style: solid;
      border-width: 1px;
      font-size: 14px;
      border-radius: 0.25rem;
      font-family: Arial;
      text-decoration: none;
      height: 24px;
      line-height: 18px;
      min-width: 28px;

      @include make-themable-global($themes) {
        color: theme-value('filter-text-button-options');
        background-color: theme-value('filter-background-button-options');
        border-color: theme-value('filter-border-button-options');
      }

      &.button-red {
        @include make-themable-global($themes) {
          background-color: theme-value('error');
          border-color: theme-value('error');
        }
      }

      &.button-navy {
        @include make-themable-global($themes) {
          background-color: theme-value('primary-schmuckfarbe');
          border-color: theme-value('primary-schmuckfarbe');
        }
      }

      &.button-transparent {
        @include make-themable-global($themes) {
          background-color: transparent;
          color: theme-value('background-body');
        }
      }
    }
  }

  //
  // ngx-bootstrap
  //

  /* Dropdown */
  .dropdown-menu {
    overflow-x: hidden !important;
    overflow-y: auto !important;

    .dropdown-item:active {
      @include make-themable-global($themes) {
        background-color: theme-value('background-dropdown-menu-item-hover');
      }
    }

    &.dropdown-menu-right {
      left: auto !important;
      right: 0px !important;
    }
  }

  /* Popover */
  .popover {
    .popover-body {
      @include make-themable-global($themes) {
        color: theme-value('text') !important;
      }
    }

    h4 {
      @include make-themable-global($themes) {
        color: theme-value('text');
      }
    }

    .input-underline {
      height: 2px;
      border-width: 0px 0px 1px 0px;
      border-style: solid;

      @include make-themable-global($themes) {
        border-color: theme-value('border-input');
      }

      .input-underline-colored {
        height: 2px;
        position: relative;
        border-style: solid;
        bottom: -1px;

        @include make-themable-global($themes) {
          border-color: theme-value('primary-schmuckfarbe');
        }
      }
    }

    &.warning {
      @include make-themable-global($themes) {
        background-color: theme-value('invalid-data-background-color');
        font-weight: 500;

        .popover-content.popover-body {
          color: theme-value('white-text-color') !important;
          line-height: 24px;
        }

        .arrow:after {
          border-top-color: theme-value('invalid-data-background-color');
        }
      }
    }
  }

  /* Forms */
  .form {
    .form-control {
      &.is-valid,
      &.is-invalid {
        padding-right: 0.75rem;
        background-image: none;
      }
    }

    .required-field::before {
      @include make-themable-global($themes) {
        color: theme-value('required-field');
      }
      content: '*';
    }
  }

  .input-group-append {
    &.is-invalid {
      > :nth-child(n) {
        @include make-themable-global($themes) {
          border-top: 1px solid theme-value('error');
          border-bottom: 1px solid theme-value('error');
        }
      }

      > :nth-last-child(1) {
        @include make-themable-global($themes) {
          border-right: 1px solid theme-value('error');
        }
      }
    }
  }

  // input + .invalid-feedback ist ein temporärer Workaround, dass die Bootstrap Regeln nicht mehr greifen

  input,
  select,
  iq-reactive-input {
    + .invalid-feedback {
      @include make-themable-global($themes) {
        display: block;
        width: 100%;
        margin-top: 0.25rem;
        font-size: 0.8125rem;
        color: theme-value('error');
      }
    }
  }

  //
  // ngx-charts
  //

  /* Diagramm Tooltip */
  .ngx-charts-tooltip-content {
    @include make-themable-global($themes) {
      background: theme-value('chart-tooltip-background') !important;
      border: solid 1px theme-value('chart-tooltip-border');
      color: theme-value('text') !important;
    }
  }

  .info-card {
    display: flex;
    padding: 8px 16px;
    width: fit-content;
    border-radius: 4px;

    @include make-themable-global($themes) {
      background-color: theme-value('card-background-color');
    }

    .caption {
      color: #2D2D2D;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      margin: auto;
      margin-bottom: 0px;

      &.no-bold {
        font-weight: 400;
      }
    }

    .current {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding: 0px 4px;
    }

    .total {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .separator {
      width: 2px;
      margin-right: 4px;
      margin-left: 8px;
      @include make-themable-global($themes) {
        background-color: theme-value('border-input');
      }
    }
  }

  .help-btn-container {
    position: relative;
    bottom: $HELP-BUTTON-BOTTOM-DISTANCE;
    margin-bottom: -$HELP-BUTTON-BOTTOM-DISTANCE;
    float: right;
  }

  .error-tooltip {
    &.tooltip {
      opacity: 1;
    }

    .tooltip-inner {
      @include make-themable-global($themes) {
        background-color: theme-value('carousel-pink');
        color: theme-value('rambutan');
        font-weight: 600;
      }
    }

    .arrow {
      &::before {
        @include make-themable-global($themes) {
          border-top-color: theme-value('carousel-pink');
        }
      }
    }
  }
}
