/* You can add bootstrap variables to this file to override the built-in custom variables
 * (https://getbootstrap.com/docs/4.0/getting-started/theming/#variable-defaults)
 */

/* Bootstrap 4 */
$body-bg: $background-body;
$primary: $primary-schmuckfarbe;
$theme-colors: (); // wird auf eine leere Map gesetzt, um vorab gesetzte Farbwerte zurückzusetzen
$colors: (); // wird auf eine leere Map gesetzt, um vorab gesetzte Farbwerte zurückzusetzen
$link-color: $primary-schmuckfarbe;
$secondary: white;
$danger: $error;
$font-size-base: 0.875rem;
$font-size-lg: 1rem;
$font-size-sm: 0.8125rem;
$small-font-size: 85%;
$line-height-base: 1.4;
$form-feedback-font-size: 0.8125rem;
$modal-header-padding: 0.9375rem;
$modal-md: 570px;
$modal-lg: 900px;
$input-btn-focus-width: 0rem; // blauer "Schein" um fokussierte Eingabefelder deaktivieren
$text-muted: $text-muted-color;
$tooltip-bg: $background-tooltip;
$nav-link-padding-y: 0.3rem;
$dropdown-link-color: $text;
$dropdown-padding-y: 0.4rem;
$dropdown-link-hover-bg: $background-dropdown-menu-item-hover;
$table-color: $text;
$table-hover-color: $text;
$table-hover-bg: $background-table-hover;
$table-border-color: $border;
$table-accent-bg: $background-table-striped-odd;
$input-border-color: $border-input;
$enable-print-styles: false;
$nav-tabs-link-active-color: $text;
$nav-tabs-link-active-bg: $background-tab-active;
$nav-tabs-link-active-border-color: $border;

/* NGX-Bootstrap */
$theme-blue: $primary-schmuckfarbe;

/* Font Awesome 4 */
$fa-font-path: '../fonts';

/* Spinkit */
$spinkit-spinner-margin: 0 auto;
$spinkit-spinner-color: $primary-schmuckfarbe;
$spinkit-size: 24px;

/* Pretty Checkbox */
$pretty--color-default: $border-input;
$pretty--color-primary: $primary-schmuckfarbe;
$pretty--color-success: $ampel-gruen;
$pretty--color-warning: $ampel-gelb;
$pretty--color-danger: $ampel-rot;
$pretty--colors: (primary, $pretty--color-primary), (success, $pretty--color-success), (warning, $pretty--color-warning),
  (danger, $pretty--color-danger); // (info, $pretty--color-info),
$pretty--2x: 1.4em !default;
$pretty-top: 15;
