@import 'iq-variables';

//
// do not theme placeholders! use theming functions in extender instead...
//

%iq-options-button {
  color: $white;
  background-color: $gray-500;
  border-color: $gray-500;
  padding: 2px 5px 2px 5px;
  text-decoration: none;
  border-style: solid;
  border-width: 1px;
  font-size: 18px;
  border-radius: 7px;
  font-family: Arial;
  text-decoration: none;
  height: 24px;
  line-height: 18px;
  min-width: 28px;

  &.small {
    height: 20px;
    font-size: 15px;
    padding: 0px 4px 2px 4px;
  }
}

%iq-options-button-hover {
  background-color: $text;
  border-color: $text;
  cursor: pointer;
}
