@import 'theme';
@import 'theme/themes';

// @page is outsite the class wrapper to prevent production build errors
@page {
  size: auto;
  margin: 0.8cm 0.3cm;
  -moz-margin: 1cm 0.3cm;
}

/* Print */
html.theme-wrapper {
  .print-modal {
    overflow: hidden !important;
    width: 1150px;
    min-width: 1150px;

    .hover-red-box {
      $shaddow-widht: 10px;

      &:hover {
        position: relative;
        -webkit-box-shadow: 0 0 $shaddow-widht red;
        -moz-box-shadow: 0 0 $shaddow-widht red;
        box-shadow: 0 0 $shaddow-widht red;

        i.remove-icon {
          color: red;
          text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
          position: absolute;
          top: 10px;
          right: 10px;
          font-size: large;

          &:hover {
            cursor: pointer;
            color: lighten(red, 25%);
          }
        }
      }
    }

    .modal-content {
      border: none;
    }

    .modal-body {
      background-color: white;
    }

    .modal-header {
      padding: 15px;
    }
  }

  @media print {
    * {
      -moz-transition: none !important;
      -o-transition: none !important;
      -webkit-transition: none !important;
      transition: none !important;
    }

    [iqPrintRemoveElement] {
      //page-break-inside: avoid !important;
      overflow: hidden !important;
    }

    .modal {
      position: relative !important;
      left: 0 !important;
      top: 0 !important;
      overflow: hidden !important;

      .modal-dialog {
        margin: 0;
      }
    }

    .print-modal {
      .modal-body {
        height: 100%;
        padding-top: 0;

        .ibox-content {
          padding-top: 0;
          padding-bottom: 0;
        }
      }

      .modal-header {
        padding: 0px !important;
        display: none !important;
      }
    }

    .table-bordered {
      .cell-right-border {
        @include make-themable-global($themes) {
          border-right: 4px solid theme-value('border-highlight') !important;
        }
      }

      .cell-left-border {
        @include make-themable-global($themes) {
          border-left: 4px solid theme-value('border-highlight') !important;
        }
      }
    }

    .table-bordered > thead > tr > th {
      background-color: $gray-100 !important;
    }

    table td.ampel-rot-background {
      @include make-themable-global($themes) {
        background-color: theme-value('ampel-rot-table-background') !important;
      }
    }

    table td.ampel-gruen-background {
      @include make-themable-global($themes) {
        background-color: theme-value('ampel-gruen-table-background') !important;
      }
    }

    table td.ampel-gelb-background {
      @include make-themable-global($themes) {
        background-color: theme-value('ampel-gelb-table-background') !important;
      }
    }

    table td.ampel-grau-background {
      @include make-themable-global($themes) {
        background-color: theme-value('ampel-grau-table-background') !important;
      }
    }

    .ampel-rot {
      @include make-themable-global($themes) {
        color: theme-value('ampel-rot') !important;
      }
    }

    html,
    body {
      width: 1150px;
      min-width: 1150px;
      height: auto !important;
      overflow: hidden !important;
      background-color: white !important;

      .modal-backdrop {
        background-color: white !important;
      }
    }

    table {
      page-break-before: avoid;

      thead {
        break-inside: auto;
      }

      .table-hover.table-bordered.table-striped {
        overflow: visible;
        margin-right: 60px;
      }

      tbody tr td {
        line-height: initial !important;
        padding: 5px !important;
      }
    }

    .iq-portal,
    .print-button {
      display: none !important;
    }

    /*
     * TODO: remove when firefox fix following bug: https://bugzilla.mozilla.org/show_bug.cgi?id=1089549
     * Overwrites Flex Styles for Bootstrap's row and col
     */
    .print-modal {
      .modal-content {
        display: block;
      }
    }

    .row {
      display: block !important;
      flex-wrap: unset !important;

      &:before,
      &:after {
        display: table !important; // 2
        content: ' ' !important; // 1
      }

      &:after {
        clear: both !important;
      }
    }

    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11,
    .col-12 {
      flex: unset !important;
      float: left !important;
      position: relative !important;
      min-height: 1px !important;

      &:before,
      &:after {
        display: table !important; // 2
        content: ' ' !important; // 1
      }

      &:after {
        clear: both !important;
      }
    }
    /* END: remove when firefox fix following bug */
  }
}
