// Basic Colors
// Nutze dieses Tool um die Farben zu benennen: http://chir.ag/projects/name-that-color/

// Weiss, Graustufen und Schwarz
$white: #ffffff;
$gray-100: #f3f3f4;
$gray-150: #f4f4f5;
$gray-200: #f0f0f0;
$gray-300: #e7e6e6;
$gray-400: #d1dade;
$gray-500: #bababa;
$gray-550: #7a7a7a;
$gray-600: #676a6c;
$gray-700: #4b4b4d;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000000;
$text-primary: #2D2D2D;
$text-secondary: #5C5F61;

$iq-grey: #b2b3b3;
$toennies-grey: #cccccc;

// Blautöne
$iq-blue: #229bd3;
$toennies-blue: #0096d5;
$regent-st-blue: #9bb4de;
$jordy-blue: #7fabf5;
$periwinkle-blue: #cdd9ee;
$tropical-blue: #b7cef7;
$blue: #0000ff;
$eastern-blue: #17a2b8;
$charlotte: #beebf9;
$sail: #a1e3f7;
$picton-blue: #14b7eb;
$bright-turquoise: #03c2ff;
$denim: #1c70c4;
$light-denim: #147ce3;
$accent-blue: #1F90CD;
$light-blue-gray: #E9F0F6;
$cyan-blue-shade: #5589B7;
$violet-blue: #A1AAD9;
$slate: #536179;

// Lilatöne
$electric-violet: #6610f2;
$electric-violet2: #b716de;
$tapestry: #b067a2;
$magenta: #ff00ff;
$cerise: #e83e8c;
$carousel-pink: #F7DAE0;

// Rottöne
$iq-red: #c70630;
$mandy: #ed5565;
$red: #ff0000;
$red2: #fb0b00;
$alizarin-crimson: #e32118;
$scarlet: #ff2118;
$grenadier: #d72203;
$dorado: #6b5857;
$rambutan: #C80531;
$rambutan-rgb: rgb(200, 5, 49);

// Orangetöne
$flamenco: #ff7f0e;
$flush-orange: #ff7700;
$orange: #F69F00;

// Gelbtöne
$supernova: #fecc00;
$school-bus-yellow: #f4d701;
$yellow: #ffff00;

// Grüntöne
$toennies-green: #00604b;
$toennies-light-green: #96c120;
$chateau-green: #3ba858;
$green: #00ff00;
$green-white: #f5f5ef;
$acapulco: #82b0a5;
$mountain-meadow: #20c997;
$mountain-meadow2: #24bf4d;
$medium-green: rgba(61, 109, 8, 0.2);
$green-leaf: #3D6D08;
$green-bamboo: #0A654D;
