@import 'iq-icon-font-variables';

//
// Icons-Font erstellt mit IcoMoon: https://icomoon.io/app
//

@font-face {
  font-family: 'iq-icon-font';
  src: url('#{$iq-icon-font-path}/iq-icon-font.eot?aer0kk');
  src: url('#{$iq-icon-font-path}/iq-icon-font.eot?aer0kk#iefix') format('embedded-opentype'),
    url('#{$iq-icon-font-path}/iq-icon-font.ttf?aer0kk') format('truetype'),
    url('#{$iq-icon-font-path}/iq-icon-font.woff?aer0kk') format('woff'),
    url('#{$iq-icon-font-path}/iq-icon-font.svg?aer0kk#iq-icon-font') format('svg');
  font-weight: normal;
  font-style: normal;
}

.iq-icon-font {
  /* use !important to prevent issues with browser extensions that change fonts */
  font: normal normal normal 14px/1 iq-icon-font;
  font-family: 'iq-icon-font' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iq-icon-font-antibiotikamonitoring {
  &:before {
    content: $iq-icon-font-antibiotikamonitoring;
  }
}
.iq-icon-font-betriebsstaetten {
  &:before {
    content: $iq-icon-font-betriebsstaetten;
  }
}
.iq-icon-font-betriebsstaetten-add {
  &:before {
    content: $iq-icon-font-betriebsstaetten-add;
  }
}
.iq-icon-font-betriebsstaetten-group {
  &:before {
    content: $iq-icon-font-betriebsstaetten-group;
  }
}
.iq-icon-font-qualitaetssicherung {
  &:before {
    content: $iq-icon-font-qualitaetssicherung;
  }
}
.iq-icon-font-blackbox1 {
  &:before {
    content: $iq-icon-font-blackbox1;
  }
}

.iq-icon-font-blackbox2 {
  &:before {
    content: $iq-icon-font-blackbox2;
  }
}
.iq-icon-font-einzeltierkennzeichnung {
  &:before {
    content: $iq-icon-font-einzeltierkennzeichnung;
  }
}
.iq-icon-font-itw {
  &:before {
    content: $iq-icon-font-itw;
    font-size: 18px;
  }
}
.iq-icon-font-pfeil-menu {
  &:before {
    content: $iq-icon-font-pfeil_menu;
  }
}
.iq-icon-font-salmonellenmonitoring {
  &:before {
    content: $iq-icon-font-salmonellenmonitoring;
  }
}
.iq-icon-font-pfeil-menu-rechts {
  &:before {
    content: $iq-icon-font-pfeil_menu_rechts;
  }
}
.iq-icon-font-widgets {
  &:before {
    content: $iq-icon-font-widgets;
  }
}
.iq-icon-font-optimalbereich {
  &:before {
    content: $iq-icon-font-optimalbereich;
  }
}
.iq-icon-font-warnung {
  &:before {
    content: $iq-icon-font-warnung;
  }
}
.iq-icon-font-tgi {
  &:before {
    content: $iq-icon-font-tgi;
  }
}
.iq-icon-font-reset {
  &:before {
    content: $iq-icon-font-reset;
  }
}
.iq-icon-font-mastgruppenbetrachtung {
  &:before {
    content: $iq-icon-font-mastgruppenbetrachtung;
  }
}
.iq-icon-font-expand-vertical {
  &:before {
    content: $iq-icon-font-expand-vertical;
  }
}
.iq-icon-font-compress-vertical {
  &:before {
    content: $iq-icon-font-compress-vertical;
  }
}
.iq-icon-font-expand-horizontal {
  &:before {
    content: $iq-icon-font-expand-horizontal;
  }
}
.iq-icon-font-compress-horizontal {
  &:before {
    content: $iq-icon-font-compress-horizontal;
  }
}
.iq-icon-font-integrity {
  &:before {
    content: $iq-icon-font-integrity;
  }
}
.iq-icon-font-intenstinal-changes {
  &:before {
    content: $iq-icon-font-intenstinal-changes;
  }
}
.iq-icon-font-raspiratory {
  &:before {
    content: $iq-icon-font-raspiratory;
  }
}
.iq-icon-font-diagram {
  &:before {
    content: $iq-icon-font-diagram;
  }
}
.iq-icon-font-sickness-statistic {
  &:before {
    content: $iq-icon-font-sickness_statistic;
  }
}
.iq-icon-font-table {
  &:before {
    content: $iq-icon-font-table;
  }
}
.iq-icon-font-stock-register {
  &:before {
    content: $iq-icon-font-stock-register;
  }
}
.iq-icon-font-clockwise {
  &:before {
    content: $iq-icon-font-clockwise;
  }
}
.iq-icon-font-anticlockwise {
  &:before {
    content: $iq-icon-font-anticlockwise;
  }
}
