@import 'theme';
@import 'theme/themes';

html.theme-wrapper {
  .alert {
    &.alert-iq-info {
      @include make-themable-global($themes) {
        color: theme-value('text-2');
        background-color: theme-value('primary-schmuckfarbe');
        border-color: theme-value('border');
      }
    }

    &.alert-iq-lightgray {
      @include make-themable-global($themes) {
        color: theme-value('text-primary');
        background-color: theme-value('color-lightgray-fill');
        border-radius: 4px;
        i {
          color: theme-value('text-secondary');
        }
      }
    }
  }
}
