$iq-icon-font-path: '../assets/fonts/iq-icon-fonts/';

$iq-icon-font-betriebsstaetten-add: '\e900';
$iq-icon-font-betriebsstaetten: '\e901';
$iq-icon-font-betriebsstaetten-group: '\e902';
$iq-icon-font-qualitaetssicherung: '\e903';
$iq-icon-font-antibiotikamonitoring: '\e904';
$iq-icon-font-blackbox1: '\e905';
$iq-icon-font-blackbox2: '\e906';
$iq-icon-font-salmonellenmonitoring: '\e907';
$iq-icon-font-einzeltierkennzeichnung: '\e908';
$iq-icon-font-itw: '\e909';
$iq-icon-font-pfeil_menu: '\e90a';
$iq-icon-font-pfeil_menu_rechts: '\e90b';
$iq-icon-font-widgets: '\e90c';
$iq-icon-font-optimalbereich: '\e90d';
$iq-icon-font-warnung: '\e90e';
$iq-icon-font-tgi: '\e90f';
$iq-icon-font-reset: '\e910';
$iq-icon-font-mastgruppenbetrachtung: '\e911';
$iq-icon-font-expand-vertical: '\e912';
$iq-icon-font-compress-vertical: '\e913';
$iq-icon-font-expand-horizontal: '\e914';
$iq-icon-font-compress-horizontal: '\e915';
$iq-icon-font-integrity: '\e916';
$iq-icon-font-intenstinal-changes: '\e917';
$iq-icon-font-raspiratory: '\e918';
$iq-icon-font-diagram: '\e919';
$iq-icon-font-sickness_statistic: '\e91a';
$iq-icon-font-table: '\e91b';
$iq-icon-font-stock-register: '\e91c';
$iq-icon-font-clockwise: '\e91d';
$iq-icon-font-anticlockwise: '\e91e';
