@import 'iq-colors';

// Theme Colors
$primary-schmuckfarbe: $blue !default;
$error: $red;

$ampel-gruen: $chateau-green !default;
$ampel-gelb: $supernova !default;
$ampel-rot: $alizarin-crimson !default;

// Various colors
$background-body: $gray-700 !default;

$text: $gray-600 !default;
$text-muted-color: lighten($gray-550, 5%) !default;

$border: $gray-300 !default;
$border-input: $gray-400 !default;
$background-dropdown-menu-item-hover: $gray-100 !default;

// Navigation
$sidebar-width: 245px !default;
$sidebar-width-mini: 70px !default;
$nav-profil-pattern: none !default; // url("../assets/images/patterns/header-profile.png");

// Tabs
$background-tab-active: $white !default;

// Tables
$background-table-hover: lighten($gray-300, 2%) !default;
$background-table-striped-odd: lighten($gray-100, 2%) !default;

$filter-font-size: 13px !default;

// Help and Print buttons
$HELP-BUTTON-BOTTOM-DISTANCE: 50px !default;