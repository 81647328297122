@import 'theme';
@import 'theme/themes';

html.theme-wrapper {
  #toast-container {
    .ngx-toastr {
      opacity: 0.9;
      border: none;

      @include make-themable-global($themes) {
        color: theme-value('text-2');
      }

      &:hover {
        opacity: 1;
      }

      &.toast-info {
        @include make-themable-global($themes) {
          background-color: theme-value('info');
        }
      }

      &.toast-success {
        @include make-themable-global($themes) {
          background-color: theme-value('success');
        }
      }

      &.toast-warning {
        @include make-themable-global($themes) {
          background-color: theme-value('warning');
        }
      }

      &.toast-error {
        @include make-themable-global($themes) {
          background-color: theme-value('error');
        }
      }
    }

    .toast-progress {
      @include make-themable-global($themes) {
        background-color: theme-value('toastr-progress');
      }
    }
  }
}
