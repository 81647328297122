@import 'theme';
@import 'theme/themes';

html.theme-wrapper {
  .bs-datepicker {
    border-radius: 2px;

    .bs-datepicker-head {
      button.previous,
      button.next {
        span {
          line-height: 0.9; // hebt die pfeile mittig in den Kreis
        }
      }
    }

    .bs-datepicker-body {
      .today {
        @include make-themable-global($themes) {
          background-color: lighten(theme-value('primary-schmuckfarbe'), 40%);
        }
      }
    }
  }
}
