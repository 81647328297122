@import 'theme';
@import 'theme/themes';

html.theme-wrapper {
  .ampel-gruen-background {
    @include make-themable-global($themes) {
      background-color: theme-value('ampel-gruen-table-background');
    }
  }

  .ampel-gelb-background {
    @include make-themable-global($themes) {
      background-color: theme-value('ampel-gelb-table-background');
    }
  }

  .ampel-rot-background {
    @include make-themable-global($themes) {
      background-color: theme-value('ampel-rot-table-background');
    }
  }

  .ampel-grau-background {
    @include make-themable-global($themes) {
      background-color: theme-value('ampel-grau-table-background');
    }
  }

  .ampel-gruen {
    @include make-themable-global($themes) {
      color: theme-value('ampel-gruen');
    }
  }

  .ampel-gelb {
    @include make-themable-global($themes) {
      color: theme-value('ampel-gelb');
    }
  }

  .ampel-rot {
    @include make-themable-global($themes) {
      color: theme-value('ampel-rot');
    }
  }

  .panel-ampel-gruen > .panel-heading {
    @include make-themable-global($themes) {
      color: theme-value('text');
      background-color: theme-value('ampel-gruen-table-background');
      border-color: theme-value('ampel-gruen-table-background');
    }
  }

  .panel-ampel-gelb > .panel-heading {
    @include make-themable-global($themes) {
      background-color: theme-value('ampel-gelb-table-background');
      border-color: theme-value('ampel-gelb-table-background');
      color: theme-value('text');
    }
  }

  .panel-ampel-rot > .panel-heading {
    @include make-themable-global($themes) {
      background-color: theme-value('ampel-rot-table-background');
      border-color: theme-value('ampel-rot-table-background');
      color: theme-value('text');
    }
  }

  .panel-ampel-grau > .panel-heading {
    @include make-themable-global($themes) {
      background-color: theme-value('ampel-grau-table-background');
      border-color: theme-value('border');
      color: theme-value('text');
    }
  }

  .panel-ampel-gruen {
    @include make-themable-global($themes) {
      border-color: theme-value('ampel-gruen-table-background');
    }
  }

  .panel-ampel-gelb {
    @include make-themable($themes) {
      border-color: theme-value('ampel-gelb-table-background');
    }
  }

  .panel-ampel-rot {
    @include make-themable-global($themes) {
      border-color: theme-value('ampel-rot-table-background');
    }
  }

  .panel-ampel-grau {
    @include make-themable-global($themes) {
      border-color: theme-value('border');
    }
  }

  table td.disabled-cell {
    @include make-themable-global($themes) {
      color: theme-value('ampel-grau');
    }
  }
}
