@import 'theme';
@import 'theme/themes';

html.theme-wrapper {
  .text-light {
    @include make-themable-global($themes) {
      color: lighten(theme-value('text'), 35%) !important;
    }
  }

  .text-light:hover {
    @include make-themable-global($themes) {
      color: lighten(theme-value('text'), 15%);
    }
  }

  .text-schmuckfarbe-primary {
    @include make-themable-global($themes) {
      color: theme-value('primary-schmuckfarbe');
    }
  }

  .text-schmuckfarbe-primary:not(.no-hover):hover {
    @include make-themable-global($themes) {
      color: lighten(theme-value('primary-schmuckfarbe'), 15%);
    }
  }

  .text-schmuckfarbe-secondary {
    @include make-themable-global($themes) {
      color: theme-value('secondary-schmuckfarbe') !important;
    }
  }

  .text-disabled {
    @include make-themable-global($themes) {
      color: lighten(theme-value('text'), 35%) !important;
    }
  }

  .text-not-allowed {
    cursor: not-allowed;
  }

  .text-danger {
    @include make-themable-global($themes) {
      color: theme-value('error');
    }
  }

  .text-bold {
    font-weight: bold;
  }

  .text-underline {
    text-decoration: underline;
  }

  .text-italic {
    font-style: italic;
  }

  .text-nowrap {
    white-space: nowrap;
  }

  .text-vergleichswerte {
    @include make-themable-global($themes) {
      color: theme-value('text-vergleichswerte');
    }
  }

  .help-block {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;

    @include make-themable-global($themes) {
      color: lighten(theme-value('text'), 20%);
    }
  }

  .required-mark {
    &:after {
      content: '*';
      @include make-themable-global($themes) {
        color: theme-value('required-field');
        font-size: 12px;
        font-weight: 800;
      }
    }
  }

  /*
   * PRINT
   */
  @media print {
    .text-light {
      @include make-themable-global($themes) {
        color: lighten(theme-value('text'), 35%) !important;
      }
    }

    .text-schmuckfarbe-primary {
      @include make-themable-global($themes) {
        color: theme-value('primary-schmuckfarbe') !important;
      }
    }

    .text-schmuckfarbe-primary:hover {
      @include make-themable-global($themes) {
        color: lighten(theme-value('primary-schmuckfarbe'), 15%) !important;
      }
    }

    .text-light:hover {
      @include make-themable-global($themes) {
        color: lighten(theme-value('text'), 15%) !important;
      }
    }

    .text-vergleichswerte {
      @include make-themable-global($themes) {
        color: theme-value('text-vergleichswerte') !important;
      }
    }
  }
}
