@import 'theme';
@import 'theme/themes';

html.theme-wrapper {
  $circle-radius: 25px;

  .panel-heading {
    font-weight: bold;
    border: none;
    height: 40px;

    @include make-themable-global($themes) {
      color: theme-value('text');
      background-color: theme-value('background-accordion-header');
    }
  }

  .panel.card.qs-warning > .panel-heading {
    @include make-themable-global($themes) {
      background-color: theme-value('ampel-rot-table-background');
    }
  }

  .panel.card.panel-default {
    border: none;
  }

  .panel-collapse .panel-body .card {
    border: none;

    @include make-themable-global($themes) {
      border-top: 1px solid theme-value('border');
    }
  }

  .panel-collapse .card-body {
    padding: 5px 10px;
    text-align: left;
  }

  .panel-collapse .card-body,
  .panel-group .panel-heading + .panel-collapse > .card-body {
    border: none;
    border-top: 0px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;

    @include make-themable-global($themes) {
      background-color: theme-value('background-accordion-body');
    }
  }

  .toggle-arrow-right {
    margin-right: 7px;
    margin-top: 6px;
  }

  .toggle-arrow-down {
    margin-right: 6px;
    margin-top: 6px;
  }

  .circle-accordion-header {
    border-radius: 50%;
    display: inline-block;
    margin-top: -7px;
    margin-right: -2px;

    @include make-themable-global($themes) {
      border: 3px solid lighten(theme-value('qs-zusammenfassung-border-accordion-circle'), 9%);
    }

    .inner {
      border-radius: 50%;
      height: $circle-radius;
      width: $circle-radius;

      @include make-themable-global($themes) {
        color: theme-value('text-2');
      }
    }
  }

  .rang-circle {
    border-radius: 50%;
    display: inline-block;
    margin-top: 2px;
    margin-right: 1px;

    .inner {
      border-radius: 50%;
      height: $circle-radius;
      width: $circle-radius;
    }
  }

  .rang-ampel-grau {
    @include make-themable-global($themes) {
      background-color: theme-value('ampel-grau');
    }
  }

  .rang-ampel-rot {
    @include make-themable-global($themes) {
      background-color: theme-value('ampel-rot');
    }
  }

  .rang-ampel-gelb {
    @include make-themable-global($themes) {
      background-color: theme-value('ampel-gelb');
    }
  }

  .rang-ampel-gruen {
    @include make-themable-global($themes) {
      background-color: theme-value('ampel-gruen');
    }
  }
}
