@import 'theme';
@import 'theme/themes';

html.theme-wrapper {
  .slimscroll-bar {
    @include make-themable-global($themes) {
      background-color: theme-value('background-slimscroll') !important;
    }
  }
}
