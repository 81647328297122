html.theme-wrapper {
  .tabs-container {
    $tabs-border-radius: 0.25rem;
    $tabs-nav-link-padding: 0.3125rem;

    .nav-tabs {
      &--vertical {
        display: flex;
        flex-flow: column nowrap;
        margin-right: 0.5rem;

        @include make-themable-global($themes) {
          border-bottom: none;
          border-right: 1px solid theme-value('border');
        }
      }

      &--left {
        .nav-item {
          &:first-of-type {
            margin-top: 0.5rem;
          }
        }

        .nav-link {
          white-space: nowrap;
          padding: $tabs-nav-link-padding $tabs-nav-link-padding;
          border-right-width: 0;

          @include make-themable-global($themes) {
            color: lighten(theme-value('text'), 25%);
          }

          &:hover {
            border-bottom-left-radius: $tabs-border-radius;
            border-top-right-radius: 0;

            @include make-themable-global($themes) {
              color: theme-value('text');
            }
          }

          &.active {
            border-bottom-left-radius: $tabs-border-radius;
            border-top-right-radius: 0;
            margin-right: -1px;

            @include make-themable-global($themes) {
              color: theme-value('text');
              border-bottom-color: theme-value('border');
              border-right-color: theme-value('background-content');
            }

            &:hover {
              @include make-themable-global($themes) {
                background-color: theme-value('background-content');
                border-color: theme-value('border') theme-value('background-content') theme-value('border')
                  theme-value('border');
              }
            }
          }
        }
      }
    }
  }
}
