@import 'iq-icon-font-variables';
@import 'placeholder/iq-buttons';
@import 'theme';
@import 'theme/themes';
@import 'is-toennies';

html.theme-wrapper {
  .iq-options-button {
    @extend %iq-options-button;
    @include make-themable-global($themes) {
      color: theme-value('filter-text-button-options');
      background-color: theme-value('filter-background-button-options');
      border-color: theme-value('filter-border-button-options');
    }
  }

  .iq-options-button:hover {
    @extend %iq-options-button-hover;
    @include make-themable-global($themes) {
      background-color: theme-value('text');
      border-color: theme-value('text');
    }
  }

  .iq-filter-add-button {
    .fa-filter {
      font-size: 1.5em;
    }

    .filter-plus {
      padding-left: 8px;
      padding-top: 3px;
    }
  }

  .iq-reset-filter-button,
  .iq-reset-filter-button:focus {
    @include make-themable-global($themes) {
      background-color: theme-value('filter-background-button-reset');
      color: theme-value('filter-text-button-reset');
      border-color: lighten(theme-value('filter-background-button-reset'), 30%);
    }

    &:hover {
      @include make-themable-global($themes) {
        color: theme-value('filter-text-button-reset');
        border-color: lighten(theme-value('filter-background-button-reset'), 10%);
      }
    }
  }

  .btn {
    &.btn-secondary {
      border-radius: 3px;

      @include make-themable-global($themes) {
        color: theme-value('text');
        border: 1px solid theme-value('border-input');
      }
    }

    &.btn-tertiary {
      border-radius: 3px;

      @include make-themable-global($themes) {
        color: theme-value('text-2');
        background-color: theme-value('tertiary-schmuckfarbe');
      }

      &:hover {
        @include make-themable-global($themes) {
          background-color: theme-value('tertiary-schmuckfarbe-hover');
        }
      }
    }

    @if $is-toennies == true {
      &.btn-toennies {
        border-radius: 30px;
      }
    }
  }

  #wrapper {
    .btn-primary.disabled,
    .btn-primary.disabled:hover,
    .btn-primary.disabled:focus,
    .btn-primary.disabled:active,
    .btn-primary.disabled.active,
    .btn-primary[disabled],
    .btn-primary[disabled]:hover,
    .btn-primary[disabled]:focus,
    .btn-primary[disabled]:active,
    .btn-primary.active[disabled],
    fieldset[disabled] .btn-primary,
    fieldset[disabled] .btn-primary:hover,
    fieldset[disabled] .btn-primary:focus,
    fieldset[disabled] .btn-primary:active,
    fieldset[disabled] .btn-primary.active {
      @include make-themable-global($themes) {
        background-color: theme-value('button-disabled');
        border-color: theme-value('button-disabled');
      }
    }

    .uebergeordnete-filter-row,
    .iq-segment-header .segmente-row,
    .global-betriebsstaetten-filter-wrapper,
    .lieferpartien-sonderfilter,
    .widgets-select-container,
    th {
      iq-multiselect,
      iq-datum-filter-btn,
      iq-schlachtgewicht-filter-btn,
      iq-tier-alter-filter-btn,
      iq-tz-kalk-filter-btn,
      .registrierungsnummer-filter {
        .btn {
          font-size: $filter-font-size;
        }
      }
    }

    .table {
      tbody {
        > tr {
          > td {
            &.btn-column {
              padding-top: 0px;
              padding-bottom: 0px;
            }
          }
        }
      }
    }
  }

  .form-group {
    &.has-error {
      .input-group {
        .input-group-btn {
          > .btn {
            @include make-themable-global($themes) {
              border-top-color: theme-value('error');
              border-bottom-color: theme-value('error');
            }

            &:last-of-type {
              @include make-themable-global($themes) {
                border-right-color: theme-value('error');
              }
            }
          }
        }
      }
    }
  }

  // Select-Button (SO-Filter)
  .iq-btn-filter {
    position: relative;
    padding: 6px 20px 6px 12px;
    font-size: 0.8125rem;

    i.iq-select-caret-icon {
      position: absolute;
      right: 7px;
      top: 8px;
    }
  }
}
